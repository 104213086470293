import { template as template_4ecef4beb67442d0bdfab03c70020c80 } from "@ember/template-compiler";
const FKLabel = template_4ecef4beb67442d0bdfab03c70020c80(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
