import { template as template_d552d5a485704209ad992c548d57cf8f } from "@ember/template-compiler";
const SidebarSectionMessage = template_d552d5a485704209ad992c548d57cf8f(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
