import { template as template_d06ed3a7b6ba4884885c24f55a6e2fa7 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d06ed3a7b6ba4884885c24f55a6e2fa7(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
