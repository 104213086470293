import { template as template_c3385b07d3cf4be89568523dc4c7c776 } from "@ember/template-compiler";
const WelcomeHeader = template_c3385b07d3cf4be89568523dc4c7c776(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
